var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-10 my-10",attrs:{"max-width":"1100"}},[_c('v-card-title',[_c('h3',[_vm._v("Job Posts")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"flat":"","items":_vm.customerCases,"items-per-page":10},scopedSlots:_vm._u([{key:"item.assignee",fn:function(ref){
var item = ref.item;
return [(!item.assignee)?_c('div',[_c('v-chip',{staticClass:"pointer",attrs:{"small":""}},[_vm._v("Unassigned")]),_c('v-icon',{attrs:{"color":"#F44336"}},[_vm._v("mdi-alert-circle-outline")])],1):_c('div',{staticClass:"user-details"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(item.assignee.name))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.assignee.email))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"pointer",attrs:{"label":"","color":_vm.getRotationStatusColor(item)}},[_vm._v(_vm._s(item.status))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h3',{staticClass:"pointer"},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.last_name))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","text-color":"white","color":_vm.getCaseColor(item)}},[_vm._v(" № "+_vm._s(item.id)+"-"+_vm._s(item.rotation_number)+" ")])]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openCaseControls(item)}}},[_vm._v("mdi-pencil-outline")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteCase(item)}}},[_vm._v("mdi-delete-outline")])]}}])})],1),_c('assign-caretaker-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }